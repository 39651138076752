body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin: 0;
    background-color: #f3f2ef;
}
.chat-container {
    width: 100%;
    max-width: 400px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.chat-header {
    background-color: #0a66c2;
    color: white;
    padding: 15px;
    font-weight: bold;
    font-size: 18px;
}
#chat-window {
    height: 400px;
    overflow-y: auto;
    padding: 15px;
    flex-grow: 1;
}
.message {
    margin: 10px 0;
    padding: 10px 15px;
    border-radius: 20px;
    max-width: 80%;
    word-wrap: break-word;
    line-height: 1.4;
}
.user-message {
    background-color: #0a66c2;
    color: white;
    align-self: flex-end;
    margin-left: auto;
}
.bot-message {
    background-color: #f3f2ef;
    color: #000;
}
#options-container {
    padding: 15px;
    border-top: 1px solid #e0e0e0;
}
.option-button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    background-color: white;
    color: #0a66c2;
    border: 1px solid #0a66c2;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}
.option-button:hover {
    background-color: #0a66c2;
    color: white;
}
#banner-generator-link {
    background-color: #0a66c2;
    color: white;
    text-align: center;
    padding: 15px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s;
}
#banner-generator-link:hover {
    background-color: #084e96;
}